import * as types from '../mutation-types'
import axios from '../../common/axios.js'
import _ from 'lodash'
import moment from 'moment'
import userflow from 'userflow.js'

// initial state
const state = {
	user: {},
	recherches: [],
	surfaces: [],
	companies_calls: [],
	downloaded_ids: [],
	favoris: [],
	favoris_ids: []
}

const getUniqueSlug = function(nom, email){
	let slug = _.kebabCase(nom)
	let i = 1

	//RZ--> On récupère les recherches de l'utilisateur
	let recherches = state.recherches.filter(r => r.email == email)
	
	//RZ--> On vérifie que le slug n'existe pas déjà
	while (recherches.map(r => r.slug_name).includes(slug)){
		slug = _.kebabCase(nom) + '_' + i
		i++
	}
	return slug
}

// getters
const getters = {
	getTotalRecherche: (state) => {
		let nbr = 0
		_.forEach(state.recherches, function(recherche){
			if(recherche.nom != '__last__') nbr++
		})
		return nbr
	},
	getTotalFavoris: (state) => {
		return Object.keys(state.favoris_ids).length
	},
	getTotalSurfaces: (state) => {
		return Object.keys(state.surfaces).length
	},
	getRecherche: (state, getters) => (recherche_nom) => {
		let recherche = null
		state.recherches.forEach(function(_recherche){
			if(_recherche.nom == recherche_nom){
				recherche = _.cloneDeep(_recherche)
			}
		})
		return recherche
	},
	hasLastRecherche: (state) => {
		let has = false
		state.recherches.forEach(function(_recherche){
			if(_recherche.nom == '__last__') has = true
		})
		return has
	},
	userCanExport: (state) => {
		return state.user && ['admin_vuet', 'inex'].includes(state.user.type_membre_equipe)
	},
	getLastSearch: (state) => {
		let recherche_recup_auto = null
		if(state.recherches && state.recherches.length){
			let last      = null
			let pardefaut = null
			state.recherches.forEach(function(recherche){
				if(recherche.nom == '__last__') 										last 		= _.cloneDeep(recherche)
				if(recherche.pardefaut == 1 && state.user.email == recherche.email) 	pardefaut 	= _.cloneDeep(recherche)
			})
			if(pardefaut != null){
				recherche_recup_auto = pardefaut
			}else if(last != null){
				recherche_recup_auto = last
			}
		}
		return recherche_recup_auto
	}
}

// actions
const actions = {
	init_user_data({ rootState, commit, state, dispatch, rootGetters }, data) {

		if (typeof data.user != 'undefined') {

			//AS--> Initialisation de UserFlow si le sourcing en possède in et si l'utilisateur est connecté
			if(rootGetters.canUserflow){
				userflow.init(rootState.ui.sourcing.userflow_token)
				userflow.identify(data.user.id, {
					name: data.user.nom + ' ' + data.user.prenom,
					email: data.user.email,
					// signed_up_at: 'USER_SIGNED_UP_AT',
					langue: data.user.langue
				})
			}
			
			commit('USER_SET_USER', data.user);
			//AS--> Check de principe chez iNex pour vérifier que le cookie est raccord. Si KO, ça déconnectera automatiquement l'utilisateur
			axios.get('api=inex/login/check')
		}

		if (typeof data.companies_calls != 'undefined') {
			commit('USER_SET_COMPANIES_CALLS', data.companies_calls);
		}

		if (typeof data.recherches != 'undefined') {
			commit('USER_SET_RECHERCHES', data.recherches);
		}

		if (typeof data.downloaded_ids != 'undefined') {
			commit('USER_SET_DOWNLOADED_IDS', data.downloaded_ids);
		}

		if (typeof data.surfaces != 'undefined') {
			commit('USER_SET_SURFACES', data.surfaces);
		}

		if (typeof data.favoris != 'undefined') {
			commit('USER_SET_FAVORIS', data.favoris);
			let favoris_ids = {};

			_.each(data.favoris, function(cat){
				_.each(cat, function(fav, k){
					favoris_ids[k] = 2;
				})
			})

			commit('USER_SET_FAVORIS_IDS', favoris_ids);
		}

		dispatch('init_pendo')
	},
	init_pendo({ rootState, commit, dispatch, state }){
		pendo.initialize({
		    visitor: {
				id: 		state.user.id,
				email: 		state.user.email,
				full_name: 	state.user.nom + ' ' + state.user.prenom
		    },
		    account: {
		        id:			rootState.ui.sourcing.url_sourcing
		    }
		})
	},
	user_after_connection({ rootState, commit, dispatch, state, getters, rootGetters}){

		console.log('----------- user_after_connection -----------')

		//AS--> Une fois l'internaute connecté, on peut vérifier la langue de navigation choisise sur son compte et adapter le contenu au besoin
		if(state.user.langue && state.user.langue != rootState.ui.langue && rootGetters.getLanguesCodes.includes(state.user.langue)){
			commit('UI_SET_LANGUE', state.user.langue);
			dispatch('uploadInitApp');
		}

		if(getters.getMenusMonentreprise.length && rootState.user.user.mesentreprises && rootState.user.user.mesentreprises.length){
			commit('UI_SET_PAGE', 'results')
			dispatch('getEntreprisesAjax');
			commit('UI_SET_CONTENT', {content: 'monentreprise'});
		}else{
			commit('UI_SET_PAGE', 'results')
			if(!getters.getLastSearch){
				dispatch('getEntreprisesAjax')
			}else{
				dispatch('initDefaultFilters')
			}
		}

		//AS--> Charge mes parcelles favorites
		if(getters.canParcelleFavoris) dispatch('getParcellesFavoris');

		//AS--> Charge les listes de parcelles
		dispatch('loadParcelLists')

		//AS--> Chargement de la liste des statuts
		dispatch('loadStatuses')

	},
	user_deconnection() {
		axios.get('/plugin_projet/plugin_projet_iannuaire/deconnect').then(function() {
			axios.get('api=inex/logout/').finally(function(){
				document.location.reload(true);
			})
		})
	},
	updateUser({ commit, dispatch, state }) {
		if (undefined != state.axios_source) state.axios_source.cancel();

		//AS--> Stock un nouveau tocken d'exécution Axios
		commit('USER_SET_ABORT_TOKEN');

		//RZ--> Envoie à Axios la référence du token d'annulation
		let axios_config = {
			cancelToken: state.axios_source.token
		}

		axios.get('/plugin_projet/plugin_projet_iannuaire/get_user_data', axios_config)
			.then(function(response) {
				dispatch('init_user_data', response.data);
			}).catch(function(error) {
				console.log(error);
			})
	},
	addFavori({ commit, dispatch, state }, company_id){
		let favoris_ids = _.clone(state.favoris_ids);
		favoris_ids[company_id] = 1
		commit('USER_SET_FAVORIS_IDS', favoris_ids)
		axios.post('api=inex/users/{user_email}/views/{view_code}/favorites/companies/'+company_id).then(function(){
			dispatch('updateFavorites');
		})
	},
	deleteFavori({ commit, dispatch, state }, company_id){
		let favoris_ids = _.clone(state.favoris_ids);
		delete favoris_ids[company_id];

		commit('USER_SET_FAVORIS_IDS', favoris_ids)

		axios.delete('api=inex/users/{user_email}/views/{view_code}/favorites/companies/'+company_id).then(function(){
			dispatch('updateFavorites');
		})
	},
	updateFavorites({ commit, dispatch, state }, company_id){
		axios.get('api=inex/users/{user_email}/views/{view_code}/favorites/companies').then(function(response) {
			commit('USER_SET_FAVORIS', response.data);
		})
	},
	switchFavori({ commit, dispatch, state }, company_id){
		if (Object.keys(state.favoris_ids).includes(company_id.toString())) {
			dispatch('deleteFavori', company_id)
		}else{
			dispatch('addFavori', company_id)
		}
	},
	addCall({ commit, dispatch, state }, company_id){
		let companies_calls = _.clone(state.companies_calls);
		companies_calls.push(company_id);
		commit('USER_SET_COMPANIES_CALLS', companies_calls)

		//AS--> Demande l'ajout du favoris en DB
		axios.get('/plugin_projet/plugin_projet_iannuaire/save_entreprise_call/' + company_id)
			.then(function(response) {
				//AS--> Demande la mise à jour des favoris
				dispatch('updateUser');

			}).catch(function(error) {})
	},
	deleteCall({ commit, dispatch, state }, company_id){
		let companies_calls = _.clone(state.companies_calls);
		delete companies_calls[_.indexOf(companies_calls, company_id)];

		commit('USER_SET_COMPANIES_CALLS', companies_calls)

		//AS--> Demande l'ajout du favoris en DB
		axios.get('/plugin_projet/plugin_projet_iannuaire/delete_entreprise_call/' + company_id)
			.then(function(response) {
				//AS--> Demande la mise à jour des favoris
				dispatch('updateUser');

			}).catch(function(error) {})
	},
	switchCall({ commit, dispatch, state }, company_id){
		console.log('switchCall', company_id);
		if (state.companies_calls.includes(company_id)) {
			dispatch('deleteCall', company_id)
		}else{
			dispatch('addCall', company_id)
		}
	},
	update_user({ commit }, user) {
		commit('USER_SET_USER', user);
	},
	update_companies_calls({ commit, state }, company_id) {
		let companies_ids = _.clone(state.companies_calls)
		companies_ids.push(company_id)
		companies_ids = _.uniq(companies_ids)
		commit('USER_SET_COMPANIES_CALLS', companies_ids);
	},
	add_recherche({ commit, state }, recherche) {
		recherche.modified = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss')

		let _recherches = _.clone(state.recherches)

		//AS--> Enlève les recherches par défaut si la nouvelle recherche l'est
		if(recherche.pardefaut){
			_recherches.forEach(function(_recherche){
				_recherche.pardefaut = 0
			})
		}

		//AS--> Ajoute la recherche aux autres recherches
		_recherches.push(_.cloneDeep(recherche))

		commit('USER_SET_RECHERCHES', _recherches)
		commit('UI_SET_RECHERCHE', recherche)
	},
	update_recherche({ commit, state }, recherche) {
		recherche.modified = moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSSSS')

		commit('UI_SET_RECHERCHE', recherche)

		let _recherches = _.cloneDeep(state.recherches)
		if(recherche.updated) recherche.updated = undefined

		//AS--> Remplace la recherche
		_recherches.forEach(function(_recherche, i){
			if(_recherche.slug_name == recherche.slug_name){
				_recherches[i] = recherche
			}
		})

		commit('USER_SET_RECHERCHES', _recherches)
	},

	//AS--> Envoie une recherche à iNex pour la sauvegarder
	send_recherche({ commit, state, dispatch }, recherche) {

		let url    = 'api=inex/users/{user_email}/views/{view_code}/searches/'
		let method = 'post'

		//AS--> Si c'est une modification
		if(recherche.slug_name){

			url += recherche.slug_name ? recherche.slug_name : recherche.nom
			method = 'put'

		//AS--> Si c'est une création
		}else{
			recherche.slug_name = getUniqueSlug(recherche.nom, recherche.email)
		}

		const request = axios({
			method  : method,
			url     : url,
			data    : recherche,
			headers : { 'Content-Type': 'application/json' }
		})

		request
		.then(function(response) {
			if (response.status == 200 || response.status == 201) {

				//AS--> Si c'est une création
				if(method == 'post'){
					//AS--> Ajoute la recherche dans le store à la volée
					dispatch('add_recherche', recherche);

				//AS--> Si c'est une modification
				}else{
					//AS--> Met à jour la recherche dans le store à la volée
					recherche.updated = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss')
					dispatch('update_recherche', recherche);
				}
			}
		})

		return request

	},

	//AS--> Charge les recherches de l'utilisateur depuis l'API iNex
	load_recherches({ commit, state }) {
		let request = axios.get('api=inex/users/{user_email}/views/{view_code}/searches/')

		request
		.then(function(response) {
			commit('USER_SET_RECHERCHES', response.data)
		})

		return request
	},
}

// mutations
const mutations = {
	[types.USER_SET_USER](state, user) {
		console.time('USER_SET_USER');
		state.user = user;
		console.timeEnd('USER_SET_USER');
	},
	[types.USER_SET_COMPANIES_CALLS](state, companies_calls) {
		console.time('USER_SET_COMPANIES_CALLS');
		state.companies_calls = companies_calls;
		console.timeEnd('USER_SET_COMPANIES_CALLS');
	},
	[types.USER_SET_RECHERCHES](state, recherches) {
		console.time('USER_SET_RECHERCHES');
		state.recherches = recherches;
		console.timeEnd('USER_SET_RECHERCHES');
	},
	[types.USER_SET_SURFACES](state, surfaces) {
		console.time('USER_SET_SURFACES');
		state.surfaces = surfaces;
		console.timeEnd('USER_SET_SURFACES');
	},
	[types.USER_SET_FAVORIS](state, favoris) {
		console.time('USER_SET_FAVORIS');
		state.favoris = favoris;
		console.timeEnd('USER_SET_FAVORIS');
	},
	[types.USER_SET_FAVORIS_IDS](state, favoris_ids) {
		console.time('USER_SET_FAVORIS_IDS');
		state.favoris_ids = favoris_ids;
		console.timeEnd('USER_SET_FAVORIS_IDS');
	},
	[types.USER_SET_DOWNLOADED_IDS](state, downloaded_ids) {
		console.time('USER_SET_DOWNLOADED_IDS');
		state.downloaded_ids = downloaded_ids;
		console.timeEnd('USER_SET_DOWNLOADED_IDS');
	},
	[types.USER_SET_ABORT_TOKEN](state, axios_source) {
		console.time('USER_SET_ABORT_TOKEN');
		state.axios_source = axios.CancelToken.source();
		console.timeEnd('USER_SET_ABORT_TOKEN');
	},
}

export default {
	state,
	getters,
	actions,
	mutations
}